import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { sort, prop, descend, filter, propSatisfies, lt } from 'ramda';
import {
  Typography,
  Box,
  Chip,
  Divider,
  Grid,
  Avatar,
  Button,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Pagination, PaginationItem } from '@material-ui/lab';

import { items } from '../../utils';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import CoverItem from '../../components/CoverItem';

export const query = graphql`
  query {
    api {
      speakers {
        edges {
          node {
            id
            name
            slug
            imageAvatarChip
            articleCount
          }
        }
      }
    }
  }
`;

const Page = ({ data, pageContext }) => {
  const { articles, pageNum, totalPages } = pageContext;

  const orderedAuthors = filter(
    propSatisfies(lt(0), 'articleCount'),
    sort(descend(prop('articleCount')), items(data.api.speakers))
  );

  return (
    <Layout>
      <SEO title="Articles" />
      <Box textAlign="center" mb={6}>
        <Typography variant="h1">Articles</Typography>
      </Box>

      <Box mb={8}>
        <Divider />
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {articles.map(({ id, title, author, date, imageThumb, slug }) => (
              <Grid item key={id} xs={12} md={4}>
                <CoverItem
                  title={title}
                  subtext={author.name}
                  timestamp={date}
                  image={imageThumb}
                  to={`/articles/${slug}`}
                />
              </Grid>
            ))}
          </Grid>

          <Box display="flex" justifyContent="center" mt={6} mb={4}>
            <Pagination
              size="large"
              page={pageNum}
              count={totalPages}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  component={Link}
                  to={item.page === 1 ? '/articles' : `/articles/${item.page}`}
                />
              )}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Box mb={4}>
                <Typography variant="h4">Authors</Typography>
              </Box>
              {orderedAuthors.map(({ id, name, slug, imageAvatarChip }) => (
                <Box key={id} display="inline-block" mr={1} mb={1}>
                  <Chip
                    clickable
                    component={Link}
                    to={`/speakers/${slug}`}
                    avatar={
                      <Avatar alt={name} src={imageAvatarChip}>
                        {name
                          .split(' ')
                          .map((w) => w.charAt(0))
                          .join('')}
                      </Avatar>
                    }
                    label={name}
                  />
                </Box>
              ))}
            </Grid>
            <Grid item xs={12} md={4}>
              <Box mb={4}>
                <Typography variant="h4">Memory Verses</Typography>
              </Box>
              <Box mb={2}>
                <Button
                  component={Link}
                  to="/memory-verses/plan/2024"
                  color="secondary"
                  endIcon={<ChevronRightIcon />}
                >
                  See 2024 Memory Verses
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default Page;
